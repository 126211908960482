
import { Hooper, Navigation, Pagination } from '@hokify/hooper';
import { defineComponent } from 'vue';
import type { PropType } from 'vue';
import { EventBus } from '../eventbus';

export default defineComponent({
	name: 'HokCarousel',
	components: {
		Hooper,
		Navigation,
		Pagination
	},
	mounted() {
		EventBus().$on('page-close-observed', this.pageCloseObserved);
	},
	props: {
		itemsToShow: { type: Number, default: 1 },
		autoPlay: { type: Boolean, default: true },
		whiteBackground: { type: Boolean },
		showNavigation: { type: Boolean, default: false },
		showPagination: { type: Boolean, default: true },
		styling: { type: String as PropType<'default' | 'border-button'>, default: 'default' }
	},
	computed: {
		carousel() {
			return this.$refs.carousel as Hooper;
		}
	},
	beforeDestroy() {
		EventBus().$off('page-close-observed', this.pageCloseObserved);
	},
	methods: {
		pageCloseObserved() {
			this.$nextTick(() => this.carousel?.update());
		}
	}
});
