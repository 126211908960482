
import HokCarousel from '@hokify/shared-components/lib/components/HokCarousel.vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Slide } from '@hokify/hooper';
import ArticleCard from '~/components/website/ArticleCard.vue';
import type { IAPIArticle } from '@hokify/magazine-interface';

@Component({
	name: 'ArticleCarousel',
	components: {
		ArticleCard,
		HokCarousel,
		Slide
	}
})
export default class ArticleCarousel extends Vue {
	@Prop({ type: Array, default: () => [] }) readonly articles?: IAPIArticle[];
}
