
import { Component, Prop, Vue } from 'vue-property-decorator';
import AppstoreRatings from '~/components/website/AppstoreRatings.vue';
import HokCarousel from '@hokify/shared-components/lib/components/HokCarousel.vue';
import { Slide } from '@hokify/hooper';

@Component({
	name: 'AppstoreRatingsCarousel',
	components: { AppstoreRatings, HokCarousel, Slide }
})
export default class AppstoreRatingsCarousel extends Vue {
	@Prop({ type: Array, default: () => [] }) readonly testimonials!: {
		rating: number;
		title?: string;
		text: string;
		name: string;
	}[];
}
