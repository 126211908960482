
import HokCarousel from '@hokify/shared-components/lib/components/HokCarousel.vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Slide } from '@hokify/hooper';

@Component({
	name: 'BranchesCarousel',
	components: {
		HokCarousel,
		Slide
	}
})
export default class BranchesCarousel extends Vue {
	@Prop({ type: Array, default: () => [] }) readonly popularLandingPages?: [];

	get groupedPopularLandingPages() {
		const lps = [...(this.popularLandingPages || [])].slice(0, 12);
		const groupedLandingPages: any[] = [];

		const chunkSize = this.$isMobile.any ? 3 : 6;
		for (let i = 0; i < lps.length; i += chunkSize) {
			const chunk = lps.slice(i, i + chunkSize);
			groupedLandingPages.push(chunk);
		}

		return groupedLandingPages;
	}
}
