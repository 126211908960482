
import { Component, Prop, Vue } from 'vue-property-decorator';
import { truncateFct } from '@hokify/shared-components/lib/helpers/truncate';
import type { IAPIArticle } from '@hokify/magazine-interface';

@Component({
	name: 'ArticleCard',
	methods: { truncateFct }
})
export default class ArticleCard extends Vue {
	@Prop({ type: Object, required: true }) readonly article!: IAPIArticle;

	@Prop({ type: Boolean, default: false }) readonly isCompanyPage!: boolean;

	@Prop({ type: String, default: 'karriere' }) readonly category?:
		| 'karriere'
		| 'press'
		| 'downloads'
		| 'partners';

	@Prop({ type: Boolean, default: false }) readonly ssr!: IAPIArticle;
}
