
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
	name: 'AppstoreRating',
	components: {}
})
export default class AppstoreRatingsCarousel extends Vue {
	@Prop({ type: Object, default: () => [] }) readonly testimonial!: {
		rating: number;
		title?: string;
		text: string;
		name: string;
	};
}
