const storeTable = {
	apple: 'https://itunes.apple.com/us/app/hokify-job-app/id1069568033?l=de&ls=1&mt=8',
	google: 'https://play.google.com/store/apps/details?id=com.yfabrik.hocknfinder'
};

function getReferrerString(utm: string): string {
	const utmParams = utm.split(',');
	return `&referrer=utm_source${utmParams[0]}&utm_medium=${utmParams[1]}&utm_campaign=${utmParams[2]}`;
}

export const storeLinkHelper = (store: string, utm?: string): string => {
	switch (store) {
		case 'google':
			return storeTable.google + (utm ? getReferrerString(utm) : '');
		case 'apple':
		default:
			return storeTable.apple + (utm ? getReferrerString(utm) : '');
	}
};
