
import { Component, Vue } from 'vue-property-decorator';

@Component({
	name: 'CompaniesStartpage',
	components: {}
})
export default class CompaniesStartpage extends Vue {
	logos = [
		{
			imgSource: 'bipa.svg',
			imgAlt: 'BIPA'
		},
		{
			imgSource: 'obi.svg',
			imgAlt: 'OBI'
		},
		{
			imgSource: 'dm.svg',
			imgAlt: 'dm'
		},
		{
			imgSource: 'doco.svg',
			imgAlt: 'DO & CO'
		},
		{
			imgSource: 'manpower.svg',
			imgAlt: 'Manpower'
		},
		{
			imgSource: 'baumit.svg',
			imgAlt: 'Baumit GmbH'
		},
		{
			imgSource: 'ikea.svg',
			imgAlt: 'IKEA'
		},
		{
			imgSource: 'billa.svg',
			imgAlt: 'BILLA'
		},
		{
			imgSource: 'hervis.svg',
			imgAlt: 'HERVIS Sport- und Mode GmbH'
		}
	];
}
