
import { Component, Mixins } from 'vue-property-decorator';
import HokVideo from '~/components/website/HokVideo.vue';
import HasInteracted from '~/mixins/HasInteracted';

@Component({
	name: 'SocialMediaStartpage',
	components: { HokVideo }
})
export default class SocialMediaStartpage extends Mixins(HasInteracted) {
	logos = [
		{ name: 'tiktok_black', link: 'https://www.tiktok.com/@hokify' },
		{ name: 'instagram', link: 'https://www.instagram.com/hokify/' },
		{ name: 'facebook', link: 'https://facebook.com/hokify.at' }
	];
}
