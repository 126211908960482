import { Component, Vue } from 'vue-property-decorator';

@Component({})
export default class HasInteracted extends Vue {
	hasInteracted = false;

	setHasInteracted() {
		this.removeEventListeners();

		this.hasInteracted = true;
	}

	mounted() {
		const options = {
			once: true,
			passive: true,
			capture: false
		};
		window.addEventListener('focus', this.setHasInteracted, options);
		window.addEventListener('keydown', this.setHasInteracted, options);
		window.addEventListener('mousemove', this.setHasInteracted, options);
		window.addEventListener('mousewheel', this.setHasInteracted, options);
		window.addEventListener('touchstart', this.setHasInteracted, options);
	}

	removeEventListeners() {
		window.removeEventListener('focus', this.setHasInteracted, { capture: false });
		window.removeEventListener('keydown', this.setHasInteracted, { capture: false });
		window.removeEventListener('mousemove', this.setHasInteracted, { capture: false });
		window.removeEventListener('mousewheel', this.setHasInteracted, { capture: false });
		window.removeEventListener('touchstart', this.setHasInteracted, { capture: false });
	}

	beforeDestroy() {
		this.removeEventListeners();
	}
}
